<template>
  <a-modal
    v-model="SelectAuditTypeVisible"
    title="选择审核方式"
    @onCancel="handleCancel"
    @ok="handleSubmit"
    :width="675"
  >
    <div class="select-audit-type-wrapper">
      <h3>素材审核分为两种模式</h3>
      <div class="desc-box">
        <div class="label">1.先审后投：</div>
        <div class="desc">{{ title }}平台会提前推送广告预算方的素材到您账户，您需要先对素材进行审核，只有审核通过的素材才可以展示出去。该方式对素材审核的效率要求比较高，需要投入的人力以及时间较多，审核的效率直接影响着您的收益。
        </div>
      </div>
      <div class="desc-box">
        <div class="label">2.先投后审：</div>
        <div class="desc">
          {{ title }}平台会统计在您应用上展示过的素材，您可以对这些素材进行审核，对不符合要求的进行屏蔽，屏蔽的素材之后不会再被展示。该方式投入的人力、时间都较小，对收益影响也较小，推荐使用该方式。
        </div>
      </div>
      <a-radio-group v-model="type">
        <a-radio :value="1"> 先审后投 </a-radio>
        <a-radio :value="0"> 先投后审 </a-radio>
      </a-radio-group>
    </div>
  </a-modal>
</template>

<script>
import { getCreativeAuditSts, updateAuditType } from '@/api/creative'
import { mapState } from 'vuex'
export default {
  name: 'SelectAuditType',
  data () {
    return {
      SelectAuditTypeVisible: false,
      type: 1
    }
  },
  computed: {
    ...mapState({
      auditType: (state) => state.user.auditType,
      token: (state) => state.user.token,
      title: (state) => state.autoweb.title
    })
  },
  watch: {
    SelectAuditTypeVisible: {
      handler (val) {
        if (val) {
          this.type = +this.auditType
        }
      },
      deep: true,
      immediate: true
    }
  },
  created () {
    this.getCreativeAuditSts()
  },
  methods: {
    async getCreativeAuditSts () {
      const { data = {} } = await getCreativeAuditSts()
      this.$store.dispatch('changeCreativeAuditSts', data.auditSts === 'A' ? 'A' : 'S')
      if (data.auditSts === 'S') {
        this.SelectAuditTypeVisible = true
      }
    },
    handleCancel () {},
    async handleSubmit () {
      const resp = await updateAuditType({
        auditType: this.type
      })
      if (resp.code === 200) {
        this.$store.dispatch('GetInfo').then((res) => {
          this.$store.dispatch('setOriginTitile', this.type)
          this.$store.dispatch('changeCreativeAuditSts', 'A')
          this.SelectAuditTypeVisible = false
        })
      }
    }
  }
}
</script>

<style lang="less" scoped>
.select-audit-type-wrapper {
  padding: 10px;
  .desc-box {
    font-size: 13px;
    line-height: 30px;
    color: #666;
    display: flex;
    line-height: 20px;
    margin-bottom: 20px;
    .label{
      max-width: 80px;
      min-width: 80px;
    }
    .desc{
      flex-grow: 1;
    }
  }
}
</style>

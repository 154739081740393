import request from '@/utils/request'
const basrUrl = '/creative'
// 获取素材审核列表
export function getCreativeList (data) {
  return request({
    url: `${basrUrl}/pager`,
    method: 'post',
    data
  })
}
// 通过审核 拒绝审核
export function audit (data) {
  return request({
    url: `${basrUrl}/audit`,
    method: 'post',
    data
  })
}
// 修改标签
export function updateTags (data) {
  return request({
    url: `${basrUrl}/updateTags`,
    method: 'post',
    data
  })
}
// 素材包列表
export function listByAuditSts () {
  return request({
    url: '/place/listByAuditSts',
    method: 'get'
  })
}
// 素材列表
export function packageList (data) {
  return request({
    url: '/c_package/list',
    method: 'post',
    data
  })
}
// 新增素材包广告位下来列表
export function platformListForCpackage (data) {
  return request({
    url: '/plat/platformListForCpackage?userId=' + data,
    method: 'get'
  })
}
// 新增素材包
export function addCpackage (data) {
  return request({
    url: '/c_package/add',
    method: 'post',
    data
  })
}
// 编辑素材包
export function updateCpackage (data) {
  return request({
    url: '/c_package/update',
    method: 'post',
    data
  })
}
// 删除素材包
export function deleteCpackage (data) {
  return request({
    url: '/c_package/delete',
    method: 'post',
    data
  })
}
// 获取账户是否开启素材审核
export function getCreativeAuditSts () {
  return request({
    url: '/creative/getAuditSts',
    method: 'get'
  })
}

// 修改素材审核
export function updateAuditType (data) {
  return request({
    url: `/creative/updateAuditType`,
    method: 'post',
    data
  })
}

// 修改素材审核
export function closeAudit () {
  return request({
    url: `/creative/closeAudit`,
    method: 'get'
  })
}
